var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c("div", { staticClass: "fontBold fontS25 padd10 x-fc" }, [
        _c("div", [
          _c("span", [_vm._v("物料名称:")]),
          _c("span", { staticClass: "annotateBlue" }, [
            _vm._v(_vm._s(_vm.billInfo.goodsName)),
          ]),
        ]),
        _c("div", { staticClass: "marL20" }, [
          _c("span", [_vm._v("分货数:")]),
          _c("span", { staticClass: "annotateBlue" }, [
            _vm._v(_vm._s(_vm.billInfo.unitQty)),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "x-fc" },
        [
          _c("TablePage", {
            ref: "tablePage",
            staticStyle: { width: "40%" },
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options1,
              callback: function ($$v) {
                _vm.options1 = $$v
              },
              expression: "options1",
            },
          }),
          _c("TablePage", {
            ref: "tablePage1",
            staticStyle: { width: "60%" },
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options2,
              callback: function ($$v) {
                _vm.options2 = $$v
              },
              expression: "options2",
            },
          }),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage2",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
      _c("Cdialog", {
        attrs: {
          title: "新增分货对象",
          width: 500,
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  { attrs: { "label-width": "120px" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "新增门店类型" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("addDistributeManually")
                              },
                            },
                          },
                          [_vm._v("手动添加门店")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("addDistributeAutomatic")
                              },
                            },
                          },
                          [_vm._v("自动添加门店（系统匹配）")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }