var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TablePageDetail", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isDetail,
            expression: "isDetail",
          },
        ],
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
      _c("TablePage", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isDetail,
            expression: "!isDetail",
          },
        ],
        on: { handleEvent: _vm.handleEvent },
        model: {
          value: _vm.options2,
          callback: function ($$v) {
            _vm.options2 = $$v
          },
          expression: "options2",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }